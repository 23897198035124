import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "store/baseQuery";
import { authApi } from "./auth.api";

export const patientApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "patientApi",
  tagTypes: ['HealthRecords', 'LabResults'],
  endpoints: (build) => ({
    getServices: build.query({
      query() {
        return {
          url: `/api/patient/user_groups/`,
          method: "GET"
        };
      },
    }),
    createHealthRecord: build.mutation({
      query(body) {
        return {
          url: `/api/health_records/`,
          method: "POST",
          body
        };
      },
      invalidatesTags: ['HealthRecords'],
    }),
    getHealthRecords: build.query({
      query({ query = '', page }) {
        return {
          url: `api/health_records/?keywords=${query}&page_size=5&offset=${page * 5}`,
          method: "GET"
        };
      },
      providesTags: (result) =>
        result?.data?.documents
          ? [...result?.data?.documents?.map(({ id }) => ({ type: 'HealthRecords', id })), { type: 'HealthRecords', id: 'LIST' }]
          : [{ type: 'HealthRecords', id: 'LIST' }],
    }),
    deleteHealthRecord: build.mutation({
      query(id) {
        return {
          url: `/api/health_records/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [
        // { type: 'HealthRecords', id },
        { type: 'HealthRecords', id: 'LIST' }
      ],
    }),
    UpdateHealthRecord: build.mutation({
      query({ body, id }) {
        return {
          url: `/api/health_records/my_health_records/${id}/`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: (p1, p2, { id }) => [{ type: 'HealthRecords', id }],
    }),
    updateIntakForm: build.mutation({
      query({ body, id }) {
        return {
          url: `/api/intake/${id}/`,
          method: "PATCH",
          body
        };
      },
    }),
    getHealthArticlesSection: build.query({
      query() {
        return {
          url: `/api/help_center/sections/`,
          method: "GET",
        };
      },
    }),
    getHealthArticles: build.query({
      query({ query = '', category = '', page = 1, perPage = 25 }) {
        return {
          url: `/api/help_center/articles/?`,
          method: "GET",
          params: {
            section: category,
            query,
            per_page: perPage,
            page,
          }
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}|category:${queryArgs.category}|perPage:${queryArgs.perPage}|query:${queryArgs.query}`;
      },
      merge: (currentCache, response, { arg }) => {
        if (arg?.query) {
          if (arg?.page > 1 && arg?.category === currentCache?.category) {
            return {
              ...response,
              results: [...currentCache?.results ?? [], ...response?.results ?? []],
            }
          }
          return {
            ...response,
          };
        }
        else if (arg?.page > 1 && arg?.category === currentCache?.category) {
          return {
            ...response,
            results: [...currentCache?.results ?? [], ...response?.results ?? []],
          }
        } else {
          return response;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        // Force refetch only if the category changes
        return currentArg?.category !== previousArg?.category
          || currentArg?.query !== previousArg?.query
          || (currentArg?.page !== previousArg?.page && previousArg?.page < currentArg?.page);
      },
      transformResponse: (response, _, { category }) => {
        return {
          ...response,
          category: category ?? '',
        }
      },
      keepUnusedDataFor: 300,
    }),
    trackRedirection: build.mutation({
      query(id) {
        return {
          url: `/api/patient_engagement/health_resources/${id}/track_redirection_event/`,
          method: "POST",
        };
      },
    }),
    addArticleReview: build.mutation({
      query(body) {
        return {
          url: `/api/patient_engagement/health_resources_review/`,
          method: "POST",
          body
        };
      },
    }),
    getHealthPlans: build.query({
      query() {
        return {
          url: `/api/consultation/health_plans/`,
          method: "GET"
        };
      },
    }),
    getChatDetails: build.query({
      query(id) {
        return {
          url: `/api/consultation/chats/${id}/`,
          method: "GET"
        };
      },
      transformResponse: (response) => response?.data?.conversation
    }),
    getMedication: build.query({
      query() {
        return {
          url: `/api/consultation/medications/`,
          method: "GET"
        };
      },
      transformResponse: (response) => response?.data?.medications ?? []
    }),
    getLabResults: build.query({
      query({ page }) {
        return {
          url: `api/consultation/labs/?&page_size=5&offset=${page * 5}`,
          method: "GET"
        };
      },
      transformResponse: (response) => {
        return {
          data: {
            labOrders: response?.data?.labOrders?.map((item) => ({
              ...item,
              url: item?.lab_results?.[0]?.document?.url
              // ...lab_results?.[0]?.document,
            })) ?? [],
            labOrdersCount: response?.data?.labOrdersCount ?? 0
          }
        }
      },
      // providesTags: (result) =>
      //   result?.data?.documents
      //     ? [...result?.data?.documents?.map(({ id }) => ({ type: 'LabResults', id })), { type: 'LabResults', id: 'LIST' }]
      //     : [{ type: 'LabResults', id: 'LIST' }],
    }),
    getFaqs: build.query({
      query(query) {
        return {
          url: `/api/help_center/faqs/?query=${query}`,
          method: "GET",
          params: {
            per_page: 25,
            page: 1,
          }
        };
      },
      transformResponse: (response) => response?.results || [],
    }),
    getStatePolicy: build.query({
      query({ state }) {
        return {
          url: `api/consultation/state_policies/?state=${state}`,
          method: "GET"
        };
      },
      transformResponse: (response) => response?.results?.[0] ?? {}
    }),
    syncGoogleCalendar: build.query({
      query() {
        return {
          url: `/api/patient/oauth_google_calendar/`,
          method: "GET"
        };
      },
    }),
    disconnectGoogleCalendar: build.mutation({
      query() {
        return {
          url: `/api/patient/google_calendar_credential/`,
          method: "DELETE"
        };
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(authApi.util.invalidateTags(['USER']));
          // dispatch(authApi.endpoints.getUser.initiate());
        } catch (err) {
          console.log("error... ", err);
        }
      },
    }),
    createStripeVerificationUrl: build.mutation({
      query(body) {
        return {
          url: `/api/billing/payments/identity_verification/`,
          method: "POST",
          body,
        };
      },
    }),
    getVerificationSession: build.query({
      query() {
        return {
          url: `/api/billing/payments/identity_verification_session/`,
          method: "GET"
        };
      },
      keepUnusedDataFor: 0,
    }),
    getProcessLab: build.query({
      query(id) {
        return {
          url: `/api/consultation/labs_processing/${id}/`,
          method: "GET"
        };
      },
    }),
    contactUs: build.mutation({
      query(body) {
        return {
          url: `/api/help_center/contact_us/`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetServicesQuery,
  useCreateHealthRecordMutation,
  useGetHealthRecordsQuery,
  useDeleteHealthRecordMutation,
  useUpdateHealthRecordMutation,
  useUpdateIntakFormMutation,
  useGetHealthArticlesQuery,
  useTrackRedirectionMutation,
  useAddArticleReviewMutation,
  useGetHealthPlansQuery,
  useGetChatDetailsQuery,
  useGetMedicationQuery,
  useGetHealthArticlesSectionQuery,
  useGetLabResultsQuery,
  useGetFaqsQuery,
  useGetStatePolicyQuery,
  useLazySyncGoogleCalendarQuery,
  useCreateStripeVerificationUrlMutation,
  useGetVerificationSessionQuery,
  useLazyGetVerificationSessionQuery,
  useDisconnectGoogleCalendarMutation,
  useGetProcessLabQuery,
  useContactUsMutation,
} = patientApi;
