import toast from "react-hot-toast"
import { Alert } from "@mui/material"
import { getMonthNumber, months } from "./constants"
import { localStorageService } from "shared/services/localStorageService"

export const customAlert = ({ severity, message }) =>
  toast.custom(
    <Alert variant="filled" severity={severity}>
      {message}
    </Alert>
  )

export const dateFromatter = (value, withDash = false) => {
  const date = new Date(value)
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()

  return withDash ? `${day}-${month}-${year}` : `${day} ${month}, ${year}`
}

export const slotFormatter = date => {
  let day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  if (day < 10) {
    day = `0${day}`
  }
  return `${year}-${getMonthNumber[month]}-${day}`
}

export const calculateTimeWithSeconds = seconds => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`
  return `${formattedMinutes}:${formattedSeconds}`
}

// export const notificationTimeDifference = (date) => {
//   const currentDate = new Date();
//   const notificationDate = new Date(date);
//   const difference = currentDate - notificationDate;
//   const seconds = Math.floor(difference / 1000);
//   const minutes = Math.floor(seconds / 60);
//   const hours = Math.floor(minutes / 60);
//   const days = Math.floor(hours / 24);
//   const months = Math.floor(days / 30);
//   const years = Math.floor(months / 12);

// }

export const notificationTimeDifference = date => {
  const startDate = new Date(date)
  const endDate = new Date()

  const differenceInMillis = Math.abs(endDate - startDate)

  const millisecondsInSecond = 1000
  const millisecondsInMinute = millisecondsInSecond * 60
  const millisecondsInHour = millisecondsInMinute * 60
  const millisecondsInDay = millisecondsInHour * 24
  const millisecondsInWeek = millisecondsInDay * 7
  const millisecondsInMonth = millisecondsInDay * 30 // Approximation
  const millisecondsInYear = millisecondsInDay * 365 // Approximation

  if (differenceInMillis < millisecondsInMinute) {
    const seconds = Math.floor(differenceInMillis / millisecondsInSecond)
    return `${seconds} sec${seconds !== 1 ? "s" : ""} ago`
  } else if (differenceInMillis < millisecondsInHour) {
    const minutes = Math.floor(differenceInMillis / millisecondsInMinute)
    return `${minutes} min${minutes !== 1 ? "s" : ""} ago`
  } else if (differenceInMillis < millisecondsInDay) {
    const hours = Math.floor(differenceInMillis / millisecondsInHour)
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`
  } else if (differenceInMillis < millisecondsInWeek) {
    const days = Math.floor(differenceInMillis / millisecondsInDay)
    return `${days} day${days !== 1 ? "s" : ""} ago`
  } else if (differenceInMillis < millisecondsInMonth) {
    const weeks = Math.floor(differenceInMillis / millisecondsInWeek)
    return `${weeks} week${weeks !== 1 ? "s" : ""} ago`
  } else if (differenceInMillis < millisecondsInYear) {
    const months = Math.floor(differenceInMillis / millisecondsInMonth)
    return `${months} month${months !== 1 ? "s" : ""} ago`
  } else {
    const years = Math.floor(differenceInMillis / millisecondsInYear)
    return `${years} year${years !== 1 ? "s" : ""} ago`
  }
}

export const getAppointmentStatus = ({ startDate, endDate }) => {
  const currentDate = new Date()
  const start = new Date(startDate)
  const end = new Date(endDate)

  const inProgressStart = new Date(start.getTime() - 30 * 60 * 1000)
  if (currentDate < inProgressStart) return "Scheduled"
  else if (currentDate >= inProgressStart && currentDate <= end)
    return "In Progress"
  else if (currentDate > end) return "Completed"
  else return "Scheduled"
}

export const isLessThanRescheduleThreshold = ({
  appointmentTime,
  threshold
}) => {
  const currentTime = new Date()
  const appointmentDate = new Date(appointmentTime)

  // Calculate the difference in milliseconds
  const timeDifference = appointmentDate - currentTime

  // Convert milliseconds to hours
  const hoursDifference = timeDifference / (1000 * 60 * 60)

  // Check if the difference is less than 24 hours
  return hoursDifference < threshold
}

export const joinSession = zoomUrl => () => {
  if (zoomUrl) {
    window.open(zoomUrl, "_blank")
  }
}

export const getAppointmentColor = status => {
  if (status === "Scheduled") {
    return { backgroundColor: "#ECFAFF", color: "#2593BB" }
  } else if (status === "In Progress") {
    return { backgroundColor: "#FBF4F8", color: "#E3757A" }
  } else if (status === "Completed") {
    return { backgroundColor: "#F6F1FE", color: "#6D35B6" }
  } else if (status === "Cancelled") {
    return { backgroundColor: "#FBEEED", color: "#CC0909" }
  } else {
    return { backgroundColor: "#ECFAFF", color: "#2593BB" }
  }
}

// write a function if current time is less than 48 hours of appointment time
export const isGreaterThanCancelAppointmentthresholdTime = ({
  appointmentTime,
  threshold
}) => {
  const currentTime = new Date()
  const appointmentDate = new Date(appointmentTime)

  // Calculate the difference in milliseconds
  const timeDifference = appointmentDate - currentTime

  // Convert milliseconds to hours
  const hoursDifference = timeDifference / (1000 * 60 * 60)

  // Check if the difference is less than 48 hours
  return hoursDifference >= threshold
}

// write a function to check if payment method is expired
export const isPaymentMethodExpired = ({ expYear, expMonth }) => {
  const expiry = new Date(expYear, expMonth)
  const currentDate = new Date()

  return currentDate >= expiry
}

export const getImageUrl = (html = "") => {
  const imgRegex = /<img[^>]+src="([^">]+)"/
  const match = html?.match(imgRegex)

  if (match && match[1]) {
    const firstImgUrl = match[1]
    return firstImgUrl
    // console.log(firstImgUrl); // Outputs: https://zynorihelp.zendesk.com/hc/article_attachments/30112228067739
  } else {
    return ""
  }
}


export const saveLastLocation = () => {
  const currentLocation = `${window.location.pathname}${window.location.search}`;
  localStorageService.setLastLocation(currentLocation);
  // localStorageService.removeSelfLogout();
}

export const checkIfStateChange = ({ currentState, newState, values }) => {
  const { state, ...rest } = values;
  if (currentState === newState) {
    return rest;
  }
  return { state, ...rest };
}

export const convertToFeetAndInches = (heightInInches) => {
  if (!heightInInches) return 'NA';
  const totalInches = parseFloat(heightInInches);
  const feet = Math.floor(totalInches / 12);
  const inches = (totalInches % 12).toFixed(1); // Keep one decimal place for inches
  return `${feet} ft ${inches} in`;
};

export const calculatePackagePrice = ({ discount = 0, packagePrice = 0, supplementPrice = 0 }) => {
  discount = parseFloat(discount);
  packagePrice = parseFloat(packagePrice);
  supplementPrice = parseFloat(supplementPrice);
  const packTotal = packagePrice + supplementPrice;
  if (discount === 0) return { totalPrice: packTotal, discountPrice: null };
  return { discountPrice: packTotal - (packTotal * (discount / 100)), totalPrice: packTotal };
}

export const parseString = (value) => {
  try {
    // Try parsing the notes as JSON
    return JSON.parse(value);
  } catch (error) {
    // If parsing fails, return the original string
    return {};
  }
}
