import { Stack } from '@mui/material'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import ModalBox from 'components/ModalBox'
// import SelectInput from 'components/SelectInput';
import InputField from 'components/InputField';
// import UploadRecordInput from 'components/PatientHealthRecords/UploadRecordInput';
import ActionButtons from 'components/ModalBox/ActionButtons';
import { useContactUsMutation } from 'apis/patient.api';
import { customAlert } from 'utils/helperFunctions';
import useSelf from 'shared/hooks/useSelf';

const schema = yup.object().shape({
  // category: yup.string().required("Category is required"),
  // email: yup.string().required("Email is required"),
  content: yup.string().required("Content is required"),
  // uploaded_file: yup.array()
  //   // .min(1, 'File is required')
  //   .max(1, 'File is required')
  //   .of(yup.mixed().required('File is required')),
});

const Feedback = ({ open, handleClose }) => {
  const [addFeedback, { isLoading }] = useContactUsMutation();
  const { email } = useSelf();
  const methods = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   uploaded_file: [],
    // }
  });

  const onSubmit = async (values) => {
    addFeedback({ ...values, email }).unwrap().then(() => {
      customAlert({ message: "Feedback submitted successfully", severity: "success" });
      handleClose();
    }).catch((e) => console.log(e));
  };

  return (
    <ModalBox
      maxWidth='600px'
      headerTitle="Contact Us"
      open={open}
      handleClose={handleClose}
    >
      <FormProvider {...methods}>
        <Stack
          component='form'
          onSubmit={methods.handleSubmit(onSubmit)}
          direction="column"
          gap={1}
        >
          {/* <SelectInput
            name="category"
            label="Feedback Category"
            options={[]}
            placeholder="Select a category"
          /> */}

          {/* <InputField
            name="email"
            label="Email"
            placeholder="Enter email here"
          /> */}

          <InputField
            name="content"
            label="Content"
            placeholder="Enter content here"
            className="textArea"
            minRows={5}
            maxRows={5}
            multiline
          />

          {/* <UploadRecordInput
            name="uploaded_file"
            label="Attach files (optional)"
          /> */}

          <ActionButtons
            confirmText="Submit"
            isLoading={isLoading}
            handleCancel={handleClose}
            fullWidth={true}
            sx={{ p: '12px 0px' }}
          />
        </Stack>
      </FormProvider>

    </ModalBox>
  )
}

export default Feedback