import { Stack, Typography, IconButton } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { blueLabelColor } from 'theme/colors';

const ModalHeader = ({ handleClose, title }) => {
  return (
    <Stack p={2} borderBottom="1px solid #DEE2E6" direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant='h6' color="rgba(30, 43, 73, 1)" fontWeight={600} >{title}</Typography>
      <IconButton onClick={handleClose}> <CloseOutlinedIcon sx={{ color: blueLabelColor }} /> </IconButton>
    </Stack>
  )
}

export default ModalHeader