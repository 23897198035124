import { useCallback, useRef, useState } from 'react'

const useDeleteModalState = (open = false) => {
  const [openConfirm, setOpenConfirm] = useState(open);
  const id = useRef(null);

  const openConfirmModal = useCallback(() => {
    setOpenConfirm(true);
  }, [])

  const closeConfirm = useCallback(() => {
    id.current = null;
    setOpenConfirm(false);
  }, [])

  const transitionClose = useCallback(({ duration = 300 }) => {
    setOpenConfirm(false);
    setTimeout(() => {
      id.current = null;
    }, duration)
  }, [])

  const setId = useCallback((value) => {
    id.current = value;
  }, [])


  return {
    openConfirmModal,
    closeConfirm,
    id: id.current,
    setId,
    openConfirm,
    transitionClose
  }
}

export default useDeleteModalState